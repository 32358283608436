<template>
  <v-text-field
    v-bind="$attrs"
    :id="id"
    :type="showPassword ? 'text' : 'password'"
    v-model="password"
    @input="$emit('input', $event)"
    autocomplete="password"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPassword = !showPassword"
    required
    :label="`${$t(label)} *`"
    outlined
    :hint="password === '' ? $t('auth.sign_in.password_hint') : ''"
  ></v-text-field>
</template>

<script>
export default {
  name: 'Password',
  props: {
    label: {
      type: String,
      default: 'auth.password',
    },
    id: {
      type: String,
      default: 'password',
    },
  },
  data() {
    return {
      password: '',
      showPassword: false,
    };
  },
};
</script>
